import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  OverlayTrigger,
  Table,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "../../../css/storeListing/storeListing.css";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";

const StoreListing = () => {
  const { t } = useTranslation();

  let coloumns = [
    t("store.name"),
    t("store.created_on"),
    t("store.status"),
    "Connect Ads",
    t("store.actions"),
  ];
  const navigate = useNavigate();
  const { dispatch } = useContext(Context);
  const [page, setPage] = useState(1);
  const [limit, setlimit] = useState(10);
  const options = { day: "numeric", month: "short", year: "numeric" };
  const [totalEntries, setTotalEntries] = useState(0);
  const [StoreData, setStoreData] = useState([]);
  const [PreviousStoreData, setPreviousStoreData] = useState([]);
  const [editeddata, setediteddata] = useState([]);
  const [SearchValue, setSearchValue] = useState("");
  const [sort, setsort] = useState({ name: 0, createdAt: 0, status: 0 });
  const [Editdata, setEditdata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const user_type = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user") || {})?.user_type
    : "";
  // COMMON FETCH STORE DATA
  const fetchData = async (
    sortid = "createdAt",
    sortascdsc,
    searchvalue = null
  ) => {
    try {
      let response = await Axios.get(
        `/api/store?search=${SearchValue}&page=${page}&limit=${limit}&sortby=${sortid}&sort=${
          sortascdsc || 1
        }`
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.data && response.data.result) {
        const { results, totalCount } = response?.data?.result;
        setStoreData(results ? results : []);
        setTotalEntries(totalCount ? totalCount : 0);
        setPageCount(Math.ceil(totalCount / limit));
      } else {
        console.error("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //COMMON CURRENT SORTBY AND SORT VALUES
  function currentSortValue() {
    let soryBy;
    let sortValue;
    if (sort.name) {
      soryBy = "name";
      sortValue = sort.name;
    } else if (sort.createdAt) {
      soryBy = "createdAt";
      sortValue = sort.createdAt;
    } else if (sort.status) {
      soryBy = "status";
      sortValue = sort.status;
    }
    return { soryBy, sortValue };
  }

  useEffect(() => {
    const { soryBy, sortValue } = currentSortValue();
    fetchData(soryBy, sortValue, SearchValue);
  }, [page, limit]);

  //STATUS TOGGLE SWITCH HANDLER
  const toggleSwitch = async (data, i) => {
    try {
      data.status = data.status === 0 ? 1 : 0;
      const editData = { _id: data._id, status: data.status };
      const updatedres = await Axios.patch("/api/store", editData);
      if (updatedres.data.result.acknowledged) {
        const updatedresults = StoreData.map((e) => {
          if (e._id === data._id) {
            e.status = data.status;
            return e;
          }
          return e;
        });
        setStoreData(updatedresults);
      } else {
        toast.error(t("store.toast_message_1"), {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  //DELETE STORE DATA HANDLER
  const [DeleteData, setDeleteData] = useState();
  const [DeleteDataModal, setDeleteDataModal] = useState(false);
  const deleteModal = async (data) => {
    const { __v, ...newObject } = data;
    setDeleteData(newObject);
    setDeleteDataModal(true);
  };
  const handleStoreDelete = async (newObject) => {
    try {
      // const { __v, ...newObject } = data;
      setDeleteDataModal(false);
      await Axios.delete("/api/store", newObject);
      toast.success(t("store.toast_message_2"), {
        position: "top-center",
        autoClose: 3000,
      });
      const { soryBy, sortValue } = currentSortValue();
      fetchData(soryBy, sortValue);
    } catch (error) {
      console.error(error);
    }
  };

  // EDIT FUNCTIONALITY STARTS HERE
  const handleEditButton = async (data, index) => {
    try {
      const previousData = data;
      setPreviousStoreData([
        ...PreviousStoreData,
        { name: previousData.name, _id: previousData._id },
      ]);
      setEditdata([...Editdata, data]);
    } catch (error) {
      console.error(error);
    }
  };
  const handleEditStoreName = (e, data) => {
    const text = e.target.value;
    for (let i = 0; i < StoreData.length; i++) {
      if (StoreData[i]._id === data._id) {
        StoreData[i].name = text;
      }
    }
    const hasUpdatedData = editeddata.some((e) => e.id === data._id);
    if (hasUpdatedData) {
      const updatedData = editeddata.map((item) => {
        if (item.id === data._id) {
          return { ...item, textdata: text };
        }
        return item;
      });
      setediteddata(updatedData);
    } else {
      setediteddata([...editeddata, { textdata: text, id: data._id }]);
    }
  };
  const handleStoreNameUpdate = async (data, i) => {
    try {
      const hastobeUpdatedData = editeddata.find((e) => e.id === data._id);

      if (hastobeUpdatedData?.textdata || data?.name) {
        if (hastobeUpdatedData?.textdata.length < 3) {
          return toast.error(t("store.toast_message_3"), {
            position: "top-center",
            autoClose: 3000,
          });
        }

        data.name = hastobeUpdatedData?.textdata
          ? hastobeUpdatedData.textdata
          : data?.name;
        const updateName = { name: data.name, _id: data._id };

        const resnameupdate = await Axios.patch("/api/store", updateName);
        if (resnameupdate.data.result.acknowledged) {
          toast.success(t("store.toast_message_4"), {
            position: "top-center",
            autoClose: 3000,
          });
          const updatedresults = StoreData.map((e) => {
            if (e._id === resnameupdate._id) {
              e.name = resnameupdate.name;
              return e;
            }
            return e;
          });
          const newupdatedArray = Editdata.filter(
            (item) => item?._id !== data?._id
          );
          setEditdata(newupdatedArray);
          setStoreData(updatedresults);
        }
      } else {
        return toast.error(t("store.toast_message_5"), {
          position: "top-center",
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  // EDIT FUNCTIONALITY ENDS HERE

  //HANDLE DELETE FUNCTIONALITY OF THE UPDATED NAME
  const handledeleteupdateinput = (data, i) => {
    const previousname = PreviousStoreData.filter((e) => e._id === data._id);
    for (let i = 0; i < StoreData.length; i++) {
      if (StoreData[i]._id === data._id) {
        StoreData[i].name = previousname[0].name;
      }
    }
    const newupdatedArray = Editdata.filter((item) => item._id !== data._id);
    setEditdata(newupdatedArray);
  };

  //SORTING OPERATION STARTS HERE
  const handleSort = (e) => {
    const sortid = e.target.id;
    setPage(1);
    if (sortid === "name") {
      if (sort.name === 1) {
        setsort({ name: -1, createdAt: 0, status: 0 });
      } else {
        setsort({ name: 1, createdAt: 0, status: 0 });
      }
      fetchData(sortid, sort.name === 1 ? -1 : 1, SearchValue);
    } else if (sortid === "createdAt") {
      if (sort.createdAt === 1) {
        setsort({ name: 0, createdAt: -1, status: 0 });
      } else {
        setsort({ name: 0, createdAt: 1, status: 0 });
      }
      fetchData(sortid, sort.createdAt === 1 ? -1 : 1, SearchValue);
    } else if (sortid === "status") {
      if (sort.status === 1) {
        setsort({ name: 0, createdAt: 0, status: -1 });
      } else {
        setsort({ name: 0, createdAt: 0, status: 1 });
      }
      fetchData(sortid, sort.status === 1 ? -1 : 1, SearchValue);
    }
  };

  //SEARCHING OPERATION STARTS HERE
  const handlsearchvalue = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };
  const handleEnterKeyPress = async (e) => {
    if (e.key === "Enter") {
      setPage(1);
      let response = await Axios.get(
        `/api/store?search=${SearchValue}&page=${page}&limit=${limit}&sortby=${"name"}&sort=${1}`
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });
      if (response && response.data && response.data.result) {
        const { results, totalCount } = response?.data?.result;
        setStoreData(results ? results : []);
        setTotalEntries(totalCount ? totalCount : 0);
        setPageCount(Math.ceil(totalCount / limit));
      } else {
        setStoreData([]);
        console.error("Failed to fetch data");
      }
    }
  };

  //PAGINATION AND LIMIT
  const handlePageClick = (event) => {
    setPage(event.selected + 1);
  };
  const onClickSetLimit = (e) => {
    if (limit !== e.target.value) {
      setlimit(e.target.value);
      setPage(1);
    }
  };

  const routeAdsURL = (store) => {
    try {
      localStorage.setItem("ads_state", store?._id);
      window.location.href = `${process.env.REACT_APP_TIKTOK_ADS_API}/portal/auth?app_id=${process.env.REACT_APP_TIKTOK_ADS_APP_ID}&state=${store?._id}&redirect_uri=${process.env.REACT_APP_API_URL}/tiktok-stores/ads-callback`;
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
  };

  const disconnectAds = async (store) => {
    try {
      dispatch({ type: "loader_show", response: true });
      let response = await Axios.patch("/api/store/ads-disconnect", {
        store_id: store?._id,
      }).catch((e) => console.error("Axios-Catch: " + e.message));
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        toast.success("Disconnected Successfully", {
          position: "top-center",
          autoClose: 1500,
        });
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  };

  return (
    <>
      <Card>
        <Card.Body>
          <Card.Title>
            <div className="row">
              <div className="col-lg-6">{t("store.long_text_1")}</div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-end">
                  <div className="dropdown">
                    <button className="btn btn-outline-dark btn-xs mr-2 dropdown-toggle">
                      {limit}
                    </button>
                    <div className="dropdown-menu">
                      <div className="dropdown-item">
                        <option
                          className="limit"
                          value="10"
                          onClick={onClickSetLimit}
                        >
                          10
                        </option>
                      </div>
                      <div className="dropdown-item">
                        <option
                          className="limit"
                          value="25"
                          onClick={onClickSetLimit}
                        >
                          25
                        </option>
                      </div>
                      <div className="dropdown-item">
                        <option
                          className="limit"
                          value="50"
                          onClick={onClickSetLimit}
                        >
                          50
                        </option>
                      </div>
                      <div className="dropdown-item">
                        <option
                          className="limit"
                          value="100"
                          onClick={onClickSetLimit}
                        >
                          100
                        </option>
                      </div>
                    </div>
                  </div>
                  <Button
                    onClick={() => navigate("/tiktok-store/store-manager")}
                    className="w-auto btn-xs mr-2"
                  >
                    {" "}
                    {t("store.add_store")}
                  </Button>
                  <Form.Control
                    onKeyPress={handleEnterKeyPress}
                    value={SearchValue}
                    onChange={(e) => handlsearchvalue(e)}
                    className="w-auto form-control-sm"
                    placeholder={t("store.search_stores")}
                  />
                </div>
              </div>
            </div>
          </Card.Title>
          <Table responsive className="display w-100 dataTable">
            <thead>
              <tr role="row">
                <th
                  onClick={(e) => {
                    handleSort(e);
                  }}
                  id="name"
                  className={`text-center sorting ${
                    (sort.name === 1 ? "sorting-asc" : "") ||
                    (sort.name === -1 ? "sorting-desc" : "")
                  }`}
                >
                  {coloumns[0]} &nbsp;
                  <span
                    onClick={(e) => {
                      handleSort(e);
                    }}
                    id="name"
                  />
                </th>
                <th
                  className={`text-center sorting ${
                    (sort.createdAt === 1 ? "sorting-asc" : "") ||
                    (sort.createdAt === -1 ? "sorting-desc" : "")
                  }`}
                  onClick={(e) => {
                    handleSort(e);
                  }}
                  id="createdAt"
                >
                  {coloumns[1]} &nbsp;
                </th>
                <th
                  className={`text-center sorting ${
                    (sort.status === 1 ? "sorting-asc" : "") ||
                    (sort.status === -1 ? "sorting-desc" : "")
                  }`}
                  onClick={(e) => {
                    handleSort(e);
                  }}
                  id="status"
                >
                  {coloumns[2]} &nbsp;
                  <span
                    onClick={(e) => {
                      handleSort(e);
                    }}
                    id="status"
                  />
                </th>
                {user_type == "admin" ? (
                  <th className="text-center">{coloumns[3]}</th>
                ) : null}
                <th className="text-right">{coloumns[4]}</th>
              </tr>
            </thead>
            <tbody>
              {StoreData?.length
                ? StoreData.map((data, i) => (
                    <tr style={{ height: "3.5rem" }} key={i}>
                      <td className="text-center">
                        {Editdata.includes(data) ? (
                          <div className="d-flex gap-2">
                            <Form.Control
                              value={data.name}
                              onChange={(e) => handleEditStoreName(e, data, i)}
                              placeholder={t("store.edit_name")}
                              className="form-control-sm w-auto"
                            />
                            <div
                              onClick={() => handleStoreNameUpdate(data, i)}
                              className="btn btn-outline-success btn-xs"
                            >
                              <i
                                className="fa fa-floppy-o"
                                aria-hidden="true"
                              ></i>
                            </div>
                            <div
                              onClick={() => handledeleteupdateinput(data, i)}
                              className="btn btn-outline-danger btn-xs"
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </div>
                          </div>
                        ) : (
                          <p>
                            <span>
                              {data.name.length > 40
                                ? data.name.substring(0, 40) + "..."
                                : data.name}
                            </span>
                          </p>
                        )}
                      </td>
                      <td className="text-center">
                        {new Date(data?.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </td>
                      <td className="text-center">
                        <Form.Check
                          type="switch"
                          id={`custom-switch-${i}`}
                          checked={data.status == 1 ? true : false}
                          onChange={(e) => toggleSwitch(data, i)}
                        />
                      </td>
                      {user_type == "admin" ? (
                        <td className="text-center">
                          {data?.ads_connected ? (
                            <OverlayTrigger
                              overlay={<Tooltip>DISCONNECT NOW</Tooltip>}
                            >
                              <Button
                                variant="outline-success"
                                onClick={() => disconnectAds(data)}
                                className="btn btn-outline-success btn-xs mr-1"
                                type="button"
                              >
                                <i className="flaticon-381-link-1" />
                              </Button>
                            </OverlayTrigger>
                          ) : (
                            <OverlayTrigger
                              overlay={<Tooltip>CONNECT NOW</Tooltip>}
                            >
                              <Button
                                variant="outline-primary"
                                type="button"
                                className="btn btn-xs mr-1"
                                onClick={() => routeAdsURL(data)}
                              >
                                <i className="flaticon-381-link" />
                              </Button>
                            </OverlayTrigger>
                          )}
                        </td>
                      ) : null}
                      <td className="text-right">
                        <div className="d-flex justify-content-end">
                          <Link
                            onClick={() => handleEditButton(data, i)}
                            className="btn btn-outline-primary btn-xs mr-1"
                          >
                            <i className="fa fa-pencil"></i>
                          </Link>
                          <Link
                            onClick={() => deleteModal(data)}
                            className="btn btn-outline-danger btn-xs"
                          >
                            <i className="fa fa-trash"></i>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))
                : t("common.no_data_found")}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              {t("common.showing")} {totalEntries ? (page - 1) * limit + 1 : 0}{" "}
              {t("common.to")} {Math.min(page * limit, totalEntries) || 0}{" "}
              {t("common.of")} {totalEntries} {t("common.entries")}
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
                forcePage={page - 1}
              />
            </div>
          </div>
          <ToastContainer />
        </Card.Body>
      </Card>
      <Modal show={DeleteDataModal} onHide={() => setDeleteDataModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {t("store.delete_store")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Card>
            <Card.Body>
              {t("common.are_you_sure_you_want_to_delete")} {DeleteData?.name} ?
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleStoreDelete(DeleteData)}
          >
            {t("common.yes")}
          </Button>
          <Button variant="primary" onClick={() => setDeleteDataModal(false)}>
            {t("common.no")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StoreListing;
