import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, Modal, Table } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";
import ReactPaginate from "react-paginate";
import "../../../css/storeListing/storeListing.css";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";
import { isEmpty } from "../CommonComponents/isEmpty";

const UserManagement = () => {
  const { t } = useTranslation();

  let tagEnd = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).full_name
    : "";
  const headList = [
    {
      title: t("user_management.name"),
      className: "sorting",
      sort: "full_name",
    },
    {
      title: t("user_management.email"),
      className: "sorting",
      sort: "email",
    },
    {
      title: t("user_management.mobile_number"),
      className: "sorting",
      sort: "mobileNumber",
    },
    {
      title: t("user_management.user_type"),
      className: "sorting",
      sort: "user_type",
    },
    {
      title: t("user_management.plan"),
      className: "sorting",
      sort: "stripe_price",
    },
    {
      title: t("user_management.plan_cost"),
      className: "sorting",
      sort: "cost",
    },
    {
      title: t("user_management.joined"),
      className: "sorting",
      sort: "createdAt",
    },
    {
      title: t("user_management.stripe"),
      className: "text-right",
    },
    {
      title: t("user_management.actions"),
      className: "text-right",
    },
  ];

  const usageHeadList = [
    {
      title: "Module",
    },
    {
      title: "Daily Limit",
    },
    {
      title: "Data Limit",
    },
    {
      title: "Monthly Limit",
    },
    {
      title: "Action",
    },
  ];
  let minDate = new Date();
  const limitArray = [10, 25, 50, 100];
  const roleList = [
    { label: "Super Admin", value: "admin" },
    { label: "Team Member", value: "user" },
    { label: "Free Full Access", value: "free_full_access" },
  ];

  const { dispatch } = useContext(Context);
  const [coupons, setCoupons] = useState([]);
  const [plans, setPlans] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [fullName, setFullName] = useState("");
  const [fullNameMessage, setFullNameMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [emailMessage, setEmailMessage] = useState("");
  const [tag, setTag] = useState(`main_server,usr-mnmt,created-by-${tagEnd}`);
  const [role, setRole] = useState("");
  const [roleMessage, setRoleMessage] = useState("");
  const [password, setPassword] = useState("");
  const [passwordMessage, setPasswordMessage] = useState("");
  const [coupon, setCoupon] = useState("");
  const [plan, setPlan] = useState("");
  const [planMessage, setPlanMessage] = useState("");
  const [date, setDate] = useState(
    new Date(new Date().setDate(new Date().getDate() + 7))
  );
  const [reinit, setReinit] = useState(0);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [userType, setUserType] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [recordsCount, setRecordsCount] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [update, setUpdate] = useState(false);
  const [userSorting, setUserSorting] = useState({
    sort: "createdAt",
    sort_type: -1,
  });
  const sortType = userSorting.sort_type == -1 ? -1 : 1;
  const sortClass =
    userSorting.sort_type == -1 ? "sorting-desc" : "sorting-asc";
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [userModuleList, setUserModuleList] = useState([]);
  const [cancelModalShow, setCancelModalShow] = useState(false);
  const [userCanceledDetail, setUserCanceledDetail] = useState(null);
  const [reason, setReason] = useState("");
  const [reasonMessage, setReasonMessage] = useState("");

  useEffect(() => {
    if (reinit == 0) {
      getPlans();
      getCoupons();
    }
    getUsers();
    resetStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reinit]);

  const getPlans = async () => {
    let response = await Axios.get("/api/plans").catch((e) =>
      console.error("Axios-Catch: " + e.message)
    );
    if (response.status == 200 && response.data.data) {
      response.data.data.map((v) => {
        v.value = v.slug;
        v.label = v.name;
        return v;
      });
      setPlans(response.data.data);
    }
  };

  const getCoupons = async () => {
    let response = await Axios.get("/api/coupons").catch((e) =>
      console.error("Axios-Catch: " + e.message)
    );
    if (response.status == 200 && response.data.data) {
      response.data.data.map((v) => {
        v.value = v.name;
        v.label = v.name;
        return v;
      });
      setCoupons(response.data.data);
    }
  };

  const checkEmail = async () => {
    // eslint-disable-next-line no-useless-escape
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (emailRegex.test(email)) {
      try {
        const response = await Axios.post("/api/userexists", {
          email: email.toLocaleLowerCase(),
        }).catch((e) => {
          setEmailMessage("Server Error, Try Again Later");
          console.error("Axios-Catch: onBlurEmail " + e.message);
          return false;
        });

        if (response && response.status === 200) {
          if (response.data && response.data.statusCode === 200) {
            if (response.data.validEmail) {
              return true;
            } else {
              setEmailMessage(response.data.message);
              return false;
            }
          } else {
            setEmailMessage(response.data.message);
            return false;
          }
        } else {
          setEmailMessage("Server Error, Try Again Later");
          return false;
        }
      } catch (error) {
        setEmailMessage("Server Error, Try Again Later");
        console.error("Catch: onBlurEmail " + error.message);
        return false;
      }
    } else {
      setEmailMessage("Please Enter A Valid E-mail Address.");
      return false;
    }
  };

  const createUser = async () => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (
      !fullName ||
      !email ||
      !password ||
      !passwordRegex.test(password) ||
      !role ||
      (!plan && role == "user")
    ) {
      if (!fullName) {
        setFullNameMessage("Full Name is required.");
      }
      if (!email) {
        setEmailMessage("E-mail Address is required.");
      }
      if (!password) {
        setPasswordMessage("Password is required.");
      } else if (!passwordRegex.test(password)) {
        setPasswordMessage(
          "Password Must Contain Minimum Eight Characters With At Least One Uppercase Letter, One Lowercase Letter, One Number And One Special Character!"
        );
        return;
      }
      if (!role) {
        setRoleMessage("Role is required.");
      }
      if (!plan && role == "user") {
        setPlanMessage("Plan is required.");
      }
    } else {
      dispatch({ type: "loader_show", response: true });
      let validEmail = await checkEmail();
      if (validEmail) {
        let obj = {
          full_name: fullName,
          email: email.toLocaleLowerCase(),
          tag,
          user_type: role,
          password,
          coupon,
          plan,
          planEndDate: date,
        };
        const response = await Axios.post("/api/user/create", obj).catch(
          (e) => {
            console.error("Axios-Catch: onBlurEmail " + e.message);
          }
        );
        if (response && response.status == 200) {
          setModalShow(!modalShow);
          setReinit(reinit + 1);
        }
      }
      dispatch({ type: "loader_hide", response: true });
    }
  };

  const handlePageClick = (event) => {
    setPage(event.selected + 1);
    setReinit(reinit + 1);
  };

  const resetStates = () => {
    setFullName("");
    setFullNameMessage("");
    setEmail("");
    setEmailMessage("");
    setTag(`main_server,usr-mnmt,created-by-${tagEnd}`);
    setRole("");
    setRoleMessage("");
    setPassword("");
    setPasswordMessage("");
    setCoupon("");
    setPlan("");
    setPlanMessage("");
    setDate(new Date(new Date().setDate(new Date().getDate() + 7)));
    setUserId("");
    setModalShow(false);
  };

  const clearFilters = () => {
    setPage(1);
    setPageCount(0);
    setRecordsCount(0);
    setUsers([]);
    setSelectedPlan("");
    setUserType("");
    setUserSorting({ sort: "createdAt", sort_type: -1 });
    setReinit(reinit + 1);
  };

  const userSort = async (sort, sort_type) => {
    if (sort && sort_type) {
      setUserSorting({ sort: sort, sort_type: sort_type == -1 ? 1 : -1 });
      if (sort == "cost") {
        sort_type == -1
          ? users.sort(function (a, b) {
              return b[sort] - a[sort];
            })
          : users.sort(function (a, b) {
              return a[sort] - b[sort];
            });
      } else {
        setReinit(reinit + 1);
      }
    }
  };

  const getUsers = async () => {
    try {
      dispatch({ type: "loader_show", response: true });
      let paramsArr = [
        `page=${page}`,
        `limit=${limit}`,
        `sort=${userSorting.sort}`,
        `sort_type=${userSorting.sort_type}`,
      ];
      if (search) {
        paramsArr.push(`search=${search}`);
      }
      if (selectedPlan) {
        paramsArr.push(`plan=${selectedPlan}`);
      }
      if (userType) {
        paramsArr.push(`userType=${userType}`);
      }
      let paramsString = paramsArr.join("&");
      let response = await Axios.get("/api/user/list?" + paramsString).catch(
        (e) => console.error("Axios-Catch: " + e.message)
      );
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200 && response.data?.data) {
        setPage(response.data.data.currentPage);
        setPageCount(response.data.data.totalPages);
        setRecordsCount(response.data.data.recordsCount);
        response.data.data.results.map((v, i) => {
          v.user_type =
            v.user_type == "free_full_access"
              ? "Free Full Access"
              : v.user_type == "user"
              ? "User"
              : v.user_type == "admin"
              ? "Admin"
              : "-";
          return v;
        });
        setUsers(response.data.data.results);
      } else {
        setPage(1);
        setPageCount(0);
        setRecordsCount(0);
        setUsers([]);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  };

  const editUser = async (v, i) => {
    dispatch({ type: "loader_show", response: true });
    setUpdate(true);
    let response = await Axios.get(`/api/user/${v._id}`).catch((e) =>
      console.error("Axios-Catch: " + e.message)
    );
    if (response.status == 200 && response.data.data) {
      setUserId(v._id);
      setFullName(response.data.data.full_name);
      setEmail(response.data.data.email);
      setRole(response.data.data.user_type);
      setPlan(
        response.data.data?.plan?.slug ? response.data.data.plan.slug : ""
      );
      setDate(
        response.data.data.user_type != "admin" &&
          response.data.data.stripe_price ==
            process.env.REACT_APP_FREE_TRIAL_PLAN &&
          response.data.data.subscription.trial_ends_at
          ? new Date(response.data.data.subscription.trial_ends_at)
          : null
      );
      minDate = response.data.data?.subscription?.trial_ends_at
        ? new Date(response.data.data.subscription.trial_ends_at)
        : new Date();
      dispatch({ type: "loader_hide", response: true });
      setModalShow(!modalShow);
    } else {
      dispatch({ type: "loader_hide", response: true });
    }
  };

  const updateUser = async () => {
    if (!fullName || !email || !role || (!plan && role == "user")) {
      if (!fullName) {
        setFullNameMessage("Full Name is required.");
      }
      if (!email) {
        setEmailMessage("E-mail Address is required.");
      }
      if (!role) {
        setRoleMessage("Role is required.");
      }
      if (!plan && role == "user") {
        setPlanMessage("Plan is required.");
      }
    } else {
      dispatch({ type: "loader_show", response: true });
      let obj = {
        full_name: fullName,
        tag,
        user_type: role,
        password,
        coupon,
        plan,
        planEndDate: date,
      };
      const response = await Axios.patch(`/api/user/${userId}`, obj).catch(
        (e) => {
          console.error("Axios-Catch: " + e.message);
        }
      );
      if (response && response.status == 200) {
        setModalShow(!modalShow);
        setUserId("");
        setReinit(reinit + 1);
      }
    }
    dispatch({ type: "loader_hide", response: true });
  };

  const getUserModules = async (user_id) => {
    try {
      dispatch({ type: "loader_show", response: true });

      const response = await Axios.get(`/api/user-module/${user_id}`).catch(
        (e) => {
          console.error("Axios-Catch: " + e.message);
        }
      );
      if (response && response.status == 200) {
        setUserModuleList(response.data.data);
        setShowModuleModal(true);
      }
    } catch (error) {}

    dispatch({ type: "loader_hide", response: true });
  };

  const getUserCanceledDetail = async (user_id) => {
    try {
      dispatch({ type: "loader_show", response: true });

      const response = await Axios.get(
        `/api/user-cancle-detail/${user_id}`
      ).catch((e) => {
        console.error("Axios-Catch getUserCanceledDetail: " + e.message);
      });

      if (response && response.status == 200) {
        setUserCanceledDetail(response.data.data);
        setCancelModalShow(true);
      }
    } catch (error) {
      console.error("Catch getUserCanceledDetail: " + error.message);
    }

    dispatch({ type: "loader_hide", response: true });
  };

  const cancelSubscription = async () => {
    try {
      if (!reason) {
        setReasonMessage("Reason is required.");
      } else {
        dispatch({ type: "loader_show", response: true });
        const response = await Axios.post("/api/subscription/cancel", {
          ...userCanceledDetail,
          reason: reason,
        }).catch((e) => {
          console.error("Axios-Catch: cancelSubscription " + e.message);
        });

        if (response && response.status == 200) {
          setCancelModalShow(false);
          setReasonMessage("");
          setReason("");
        }
      }
    } catch (error) {
      console.error("Catch: cancelSubscription " + error.message);
    }
    dispatch({ type: "loader_hide", response: true });
  };

  const handleUserModuleChange = (e, index, key) => {
    let moduleList = [...userModuleList];
    moduleList[index][key] = e.target.value;
    setUserModuleList(moduleList);
  };

  const updateUserModule = async (module) => {
    try {
      dispatch({ type: "loader_show", response: true });

      const response = await Axios.patch(
        `/api/user-module/${module._id}`,
        module
      ).catch((e) => {
        console.error("Axios-Catch: " + e.message);
      });
      if (response && response.status == 200) {
      }
    } catch (error) {}

    dispatch({ type: "loader_hide", response: true });
  };

  return (
    <>
      <p className="text-right">
        <button
          type="button"
          className="btn btn-primary btn-xs"
          onClick={() => {
            setUpdate(false);
            setModalShow(!modalShow);
          }}
        >
          Add User
        </button>
      </p>

      <Card>
        <Card.Body>
          <Card.Title>
            <div className="row">
              <div className="col-lg-6">{t("user_management.users")}</div>
              <div className="col-lg-6">
                <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                  <div className="dropdown product-rating-menu mr-1">
                    <Form.Select
                      className="form-control form-control-sm"
                      key={reinit}
                      defaultValue={limit}
                      onChange={(e) => {
                        setLimit(e.target.value);
                        setReinit(reinit + 1);
                      }}
                    >
                      {limitArray.map((v, i) => {
                        return (
                          <option key={i} value={v}>
                            {v}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="dropdown product-rating-menu  mr-1">
                    <Form.Select
                      className="form-control form-control-sm"
                      key={reinit}
                      defaultValue={userType != "" ? userType : ""}
                      onChange={(e) => {
                        setUserType(e.target.value);
                        setReinit(reinit + 1);
                      }}
                    >
                      <option value="">
                        {t("user_management.select_role")}
                      </option>
                      {roleList.map((v, i) => {
                        return (
                          <option key={i} value={v.value}>
                            {v.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="dropdown product-rating-menu  mr-1">
                    <Form.Select
                      className="form-control form-control-sm"
                      key={reinit}
                      defaultValue={selectedPlan != "" ? selectedPlan : ""}
                      onChange={(e) => {
                        setSelectedPlan(e.target.value);
                        setReinit(reinit + 1);
                      }}
                    >
                      <option value="">
                        {t("user_management.select_plan")}
                      </option>
                      {plans.map((v, i) => {
                        return (
                          <option key={i} value={v.value}>
                            {v.label}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                  <div className="dropdown product-rating-menu  mr-1">
                    <Form.Control
                      value={search}
                      onBlur={() => setReinit(reinit + 1)}
                      onChange={(e) => setSearch(e.target.value)}
                      className="w-auto form-control-sm"
                      placeholder={t("user_management.search")}
                    />
                  </div>
                  <div className="dropdown product-rating-menu">
                    <Button
                      variant="outline-danger"
                      size="xs"
                      onClick={() => {
                        clearFilters();
                      }}
                    >
                      {t("user_management.clear_filter")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Card.Title>
          <Table responsive className="display w-100 dataTable">
            <thead>
              <tr role="row">
                {headList.map((v, i) => (
                  <th
                    key={i}
                    className={
                      (v.className || "") +
                      " " +
                      (v.sort && v.sort == userSorting.sort ? sortClass : "")
                    }
                    onClick={() => {
                      v.sort &&
                        userSort(
                          v.sort,
                          userSorting.sort == v.sort ? sortType : -1
                        );
                    }}
                    dangerouslySetInnerHTML={{ __html: v.title }}
                  ></th>
                ))}
              </tr>
            </thead>
            <tbody>
              {!isEmpty(users) &&
                users.map((v, i) => (
                  <tr key={i}>
                    <td>{v.full_name}</td>
                    <td>{v.email}</td>
                    <td>{v.mobileNumber}</td>
                    <td>{v.user_type}</td>
                    <td>{v.plan}</td>
                    <td>{v.cost}</td>
                    <td>
                      {moment(new Date(v.createdAt)).format("DD-MM-YYYY")}
                    </td>
                    <td className="text-right">
                      {v.stripe_id ? (
                        <Button
                          variant="outline-primary"
                          className="btn-xs"
                          onClick={() => {
                            window.open(
                              `https://dashboard.stripe.com/customers/${v.stripe_id}`,
                              "_blank"
                            );
                          }}
                        >
                          <i
                            className="fa fa-lg fa-gears"
                            aria-hidden="true"
                          ></i>
                        </Button>
                      ) : (
                        ""
                      )}
                    </td>
                    <td className="text-right">
                      <Button
                        variant="outline-dark"
                        className="px-2 py-1 mx-2 btn btn-outline-dark btn-xs"
                        onClick={() => getUserCanceledDetail(v._id)}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="outline-dark"
                        className="px-2 py-1 mr-2 btn btn-outline-dark btn-xs"
                        onClick={() => getUserModules(v._id)}
                      >
                        USAGE
                      </Button>
                      <Button
                        variant="outline-dark"
                        className="px-2 py-1 btn btn-outline-dark btn-xs"
                        onClick={() => editUser(v, i)}
                      >
                        <i className="fa fa-lg fa-pencil"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <div className="d-flex justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              {recordsCount == 0
                ? "No - Record Found"
                : `Showing ${(page - 1) * limit + 1} to ${Math.min(
                    page * limit,
                    recordsCount
                  )} of ${recordsCount} entries`}
            </div>
            <div className="dataTables_paginate paging_simple_numbers">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={2}
                marginPagesDisplayed={1}
                pageCount={pageCount}
                forcePage={page - 1}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </Card.Body>
      </Card>

      <Modal className="fade bd-example-modal-lg" show={modalShow} size="lg">
        <Modal.Header>
          <Modal.Title>
            {update
              ? t("user_management.update_user")
              : t("user_management.add_user")}
          </Modal.Title>
          <Button
            variant="danger"
            className="close"
            size="sm"
            onClick={() => {
              resetStates();
              setModalShow(!modalShow);
            }}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <div className="col-sm-6">
              <input
                type="text"
                className={`form-control form-control-sm ${
                  fullNameMessage ? "is-invalid" : ""
                }`}
                placeholder={t("user_management.full_name")}
                value={fullName}
                onChange={(e) => {
                  setFullName(e.target.value);
                  setFullNameMessage("");
                }}
                onBlur={() =>
                  !fullName
                    ? setFullNameMessage(
                        t("user_management.full_name_is_required")
                      )
                    : ""
                }
                required
              />
              {fullNameMessage && (
                <div className="invalid-feedback">{fullNameMessage}</div>
              )}
            </div>
            <div className="col-sm-6">
              <input
                type="email"
                className={`form-control form-control-sm ${
                  emailMessage ? "is-invalid" : ""
                }`}
                placeholder={t("user_management.email_address")}
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailMessage("");
                }}
                onBlur={() =>
                  !email
                    ? setEmailMessage(
                        t("user_management.email_address_is_required")
                      )
                    : ""
                }
                required
                disabled={update == true ? true : false}
              />
              {emailMessage && (
                <div className="invalid-feedback">{emailMessage}</div>
              )}
            </div>
          </div>
          <div className="input-group mb-3">
            <div className="col-sm-12">
              <input
                type="text"
                className={`form-control  form-control-sm`}
                placeholder={t("user_management.tags")}
                value={tag}
                onChange={(e) => setTag(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="input-group mb-3">
            <div className="col-sm-6">
              <Form.Select
                key={reinit}
                className={`form-control form-control-sm ${
                  roleMessage ? "is-invalid" : ""
                }`}
                defaultValue={role != "" ? role : ""}
                onChange={(e) => {
                  setRole(e.target.value);
                  setRoleMessage("");
                }}
                onBlur={() =>
                  !role
                    ? setRoleMessage(t("user_management.role_is_required"))
                    : role == "admin"
                    ? setPlanMessage("")
                    : ""
                }
              >
                <option value="">{t("user_management.select_role")}</option>
                {roleList.map((v, i) => {
                  return (
                    <option key={i} value={v.value}>
                      {v.label}
                    </option>
                  );
                })}
              </Form.Select>
              {roleMessage && (
                <div className="invalid-feedback">{roleMessage}</div>
              )}
            </div>
            <div className="col-sm-6">
              <input
                type="password"
                placeholder={t("user_management.password")}
                value={password}
                className={`form-control form-control-sm ${
                  passwordMessage && update == false ? "is-invalid" : ""
                }`}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setPasswordMessage("");
                }}
                onBlur={() =>
                  !password
                    ? setPasswordMessage(
                        t("user_management.password_is_required")
                      )
                    : ""
                }
                required
              />
              {passwordMessage && (
                <div className="invalid-feedback">{passwordMessage}</div>
              )}
            </div>
          </div>
          <div className="input-group mb-3">
            <div className="col-sm-6">
              <Form.Select
                key={reinit}
                className="form-control form-control-sm"
                defaultValue={coupon != "" ? coupon : ""}
                onChange={(e) => setCoupon(e.target.value)}
              >
                <option value="">{t("user_management.select_coupon")}</option>
                {coupons.map((v, i) => {
                  return (
                    <option key={i} value={v.value}>
                      {v.value}
                    </option>
                  );
                })}
              </Form.Select>
            </div>
            <div className="col-sm-6">
              <Form.Select
                key={reinit}
                className={`form-control form-control-sm ${
                  planMessage ? "is-invalid" : ""
                }`}
                defaultValue={plan != "" ? plan : ""}
                onChange={(e) => {
                  setPlan(e.target.value);
                  setPlanMessage("");
                }}
                onBlur={() =>
                  !plan && (!role || role == "user")
                    ? setPlanMessage(t("user_management.plan_is_required"))
                    : ""
                }
                disabled={
                  update == true &&
                  (!role || role == "admin" || role == "user") &&
                  plan != ""
                    ? true
                    : false
                }
              >
                <option value="">{t("user_management.select_plan")}</option>
                {plans.map((v, i) => {
                  if (v.value !== "free_plan")
                    return (
                      <option key={i} value={v.value}>
                        {v.label}
                      </option>
                    );
                  return null;
                })}
              </Form.Select>
              {planMessage && (
                <div className="invalid-feedback">{planMessage}</div>
              )}
            </div>
          </div>
          {plan && date != null && (
            <div className="col-sm-6">
              <DatePicker
                showIcon
                isClearable
                closeOnScroll={true}
                placeholderText={t("user_management.free_trial_end_date")}
                className="form-control form-control-sm"
                minDate={minDate}
                selected={date}
                onChange={(e) => setDate(e)}
              />
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-danger"
            size="sm"
            onClick={() => {
              resetStates();
              setModalShow(!modalShow);
            }}
          >
            {t("user_management.cancel")}
          </Button>
          <Button
            variant="primary"
            type="button"
            size="sm"
            className="btn btn-primary"
            onClick={update ? () => updateUser() : () => createUser()}
          >
            {update ? t("user_management.update") : t("user_management.create")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="fade bd-example-modal-lg"
        show={showModuleModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Update User Usage</Modal.Title>
          <Button
            variant="danger"
            className="close"
            size="sm"
            onClick={() => setShowModuleModal(false)}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div>
            {!isEmpty(userModuleList) && (
              <Table responsive className="display w-100 dataTable">
                <thead>
                  <tr role="row">
                    {usageHeadList.map((v, i) => (
                      <th key={i} className={v.className || ""}>
                        {v.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {userModuleList.map((v, i) => (
                    <tr key={i}>
                      <td className="text-left">{v?.module?.name}</td>
                      <td className="text-center">
                        {v.unlimited ? (
                          "Unlimited"
                        ) : (
                          <input
                            type="number"
                            className={`form-control form-control-sm`}
                            placeholder="Enter Daily Limit"
                            value={v?.limit_per_day || ""}
                            onChange={(e) =>
                              handleUserModuleChange(e, i, "limit_per_day")
                            }
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            required
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {v.unlimited ? (
                          "Unlimited"
                        ) : (
                          <input
                            type="number"
                            className={`form-control form-control-sm`}
                            placeholder="Enter Data Limit"
                            value={v?.show_max || ""}
                            onChange={(e) =>
                              handleUserModuleChange(e, i, "show_max")
                            }
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            required
                          />
                        )}
                      </td>
                      <td className="text-center">
                        {v.unlimited ? (
                          "Unlimited"
                        ) : (
                          <input
                            type="number"
                            className={`form-control form-control-sm`}
                            placeholder="Enter Monthly Limit"
                            value={v?.max_limit || ""}
                            onChange={(e) =>
                              handleUserModuleChange(e, i, "max_limit")
                            }
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                            required
                          />
                        )}
                      </td>
                      <td className="text-center">
                        <Button
                          variant="outline-primary"
                          className="px-2 py-1 btn-xs"
                          onClick={() => updateUserModule(v)}
                        >
                          Save
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        className="fade bd-example-modal-lg"
        show={cancelModalShow}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title> {t("user_profile.cancel_subscription")}</Modal.Title>
          <Button
            variant="danger"
            className="close"
            size="sm"
            onClick={() => setCancelModalShow(false)}
          >
            X
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <h3>User Detail</h3>
            <h5>Name: {userCanceledDetail?.full_name}</h5>
            <h5>Email: {userCanceledDetail?.email}</h5>
            <h5>Plan: {userCanceledDetail?.plan?.name}</h5>
          </div>

          {userCanceledDetail?.cancelSubscription && (
            <div className="form-group">
              <h3>User Cancellation Detail</h3>
              <h5>
                Cancel Status: {userCanceledDetail?.cancelSubscription?.status}
              </h5>
              <h5>
                Cancel Date:{" "}
                {userCanceledDetail?.cancelSubscription?.createdAt
                  ? moment(
                      userCanceledDetail?.cancelSubscription?.createdAt
                    ).format("DD/MM/YYYY hh:mm A")
                  : "-"}
              </h5>
              <h5>
                Last Date:{" "}
                {userCanceledDetail?.cancelSubscription?.ends_at
                  ? moment(
                      userCanceledDetail?.cancelSubscription?.ends_at
                    ).format("DD/MM/YYYY hh:mm A")
                  : "-"}
              </h5>
              <h6>Reason: {userCanceledDetail?.cancelSubscription?.reason}</h6>
            </div>
          )}

          {!userCanceledDetail?.cancelSubscription && (
            <div className="form-group">
              <textarea
                className={`form-control ${reasonMessage ? "is-invalid" : ""}`}
                rows="5"
                placeholder={t("user_profile.reason_for_cancellation")}
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                  setReasonMessage("");
                }}
                onBlur={() =>
                  !reason
                    ? setReasonMessage(t("user_profile.reason_is_required"))
                    : ""
                }
                required
              ></textarea>
              {reasonMessage && (
                <div className="invalid-feedback">{reasonMessage}</div>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!userCanceledDetail?.cancelSubscription && (
            <>
              <Button
                variant="danger"
                size="sm"
                onClick={() => setCancelModalShow(false)}
              >
                {t("user_profile.close")}
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={() => cancelSubscription()}
              >
                {t("user_profile.submit_request")}
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserManagement;
