import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Modal, OverlayTrigger, Popover, Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import { Context } from '../../../MasterLayout';
import Axios from '../../plugins/axios';
import InfiniteScrollWrapper from '../Products/InfiniteScrollWrapper';
import CategoryPrintify from './CategoryPrintify';

function PrintifyProducts() {
    const limit = 10;
    const { t } = useTranslation();
    const { dispatch } = useContext(Context);

    const [sorting, setSorting] = useState({ sort: 20, sort_type: 'DESC' });
    const [countryCode, setCountryCode] = useState('USA');

    const [items, setItems] = useState([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [category1, setCategory1] = useState("");
    const [category2, setCategory2] = useState("");
    const [categoryId1, setCategoryId1] = useState(0);
    const [amazonProducts, setAmazonProducts] = useState([])
    const [showAmazonProductModal, setShowAmazonProductModal] = useState(false);
    const [recordsCount, setRecordsCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [amazonProductsPage, setAmazonProductsPage] = useState(1);
    const [amazonProductsLoader, setAmazonProductsLoader,] = useState(false);
    const [amazonProductID, setAmazonProductID,] = useState(null);
    const [loader, setLoader] = useState(false);

    const headList = [
        {
            title: '#',
        },
        {
            title: t('printifyAmazonProduct.title'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.brand'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.category'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.price'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.best_seller_rank'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.ratting'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.review_count'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.weight'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.monthly_sales'),
            className: 'text-left',
        },
        {
            title: t('printifyAmazonProduct.listed_since'),
            className: 'text-left',
        },
    ];

    const handleCloseAmazonProductModal = () => {
        setAmazonProducts([])
        setRecordsCount(0);
        setAmazonProductsPage(1);
        setAmazonProductID(null)
        setShowAmazonProductModal(false);
    };
    const handleShowAmazonProductModal = async (product) => {
        try {
            getAmazonProductList(product._id, amazonProductsPage)
            setShowAmazonProductModal(true)
        } catch (error) {
            alert('An error occurred while Fetching the Amazon products');
            console.error("An error occurred while Fetching the Amazon product modal:", error);
        }
    };
    const getAmazonProductList = async (id, page) => {
        try {
            setAmazonProductsLoader(true);
            const response = await Axios.get(`/api/printifyAmazonProducts?id=${id}&page=${page}&limit=${limit}`);

            if (response && response.status === 200 && response.data?.data) {
                setAmazonProductsLoader(false);
                setPageCount(response.data.data.totalPages);
                setRecordsCount(response.data.data.recordsCount);
                setAmazonProducts(response.data.data.products);
            } else {
                setAmazonProductsLoader(false);
                setAmazonProducts([]);
                setPageCount(0);
                setRecordsCount(0);
            }
        } catch (error) {
            alert('An error occurred while Fetching the Amazon products');
            console.error("An error occurred while Fetching the Amazon product modal:", error);
        }
    };


    async function fetchProductS() {
        try {

            let params = {
                page
            };
            if (countryCode != '') {
                params['country_code'] = countryCode;
            }
            if (category1 != '') {
                params['category1'] = category1;
            }
            if (category2 != '') {
                params['category2'] = category2;
            }
            let response = await Axios.post('/api/printify', params);
            dispatch({ type: 'subscription_expired', response: response.data.statusCode });
            if (response && response.status === 200 && response.data.data) {
                setLoader(false)
                const results = response.data.data.results
                for (let i = 0; i < results.length; i++) {
                    for (let image = 0; image < results[i].images.length; image++) {
                        if (results[i].images[image].catalogPreview.includes(category1) && results[i].images[image].catalogPreview.includes("Hover Feature")) {
                            response.data.data.results[i].hoverImage = results[i].images[image].src;
                        } else if (results[i].images[image].catalogPreview.includes(category1)) {
                            response.data.data.results[i].mainImage = results[i].images[image].src;
                        }
                    }
                    if (!response.data.data.results[i].mainImage) {
                        response.data.data.results[i].mainImage = results[i].images[0].src;
                    }
                    if (!response.data.data.results[i].hoverImage) {
                        for (let hover = 0; hover < results[i].images.length; hover++) {
                            if (results[i].images[hover].catalogPreview.includes("Hover Feature")) {
                                response.data.data.results[i].hoverImage = results[i].images[hover].src;
                            }
                        }
                    }
                }

                if (page == 1) {
                    setItems(response.data.data.results.length ? response.data.data.results : []);
                } else {
                    setItems(response.data.data.results.length ? items.concat(response.data.data.results) : items);
                }

                setHasMore(response?.data?.data?.totalCount !== items.length);
            } else {
                setHasMore(false);
            }
        } catch (error) {
            console.error('Error: ' + error.message);
        }
    }

    useEffect(() => {
        setLoader(true)
        setHasMore(true);
        fetchProductS();
    }, [countryCode, category1, category2, page])


    const handleCategory = async (categoryIds, categoryNames) => {
        setCategory1(categoryIds[0] ? categoryIds[0] : '');
        setCategory2(categoryIds[1] ? categoryIds[1] : '');
        setCategoryId1(categoryIds[0] ? categoryIds[0] : 0);
        setPage(1);
    };
    const handleSort = async (sort, sortType) => {
        if (sort != sorting.sort || (sortType && sortType != sorting.sort_type)) {
            setSorting({ sort, sort_type: sortType });
        }
    };

    const functionNext = async () => {
        if (items.length > 0) {
            let number = page + 1;
            setPage(number);
        }
    }
    const tableHead = [
        {
            title: '#',
        },
        {
            title: t('common.name'),
        },
        {
            title: t('PrintifyProducts.brand_name'),
            className: 'text-left',
        },
        {
            title: t('PrintifyProducts.min_price'),
            className: 'text-left',
        },
        {
            title: t('PrintifyProducts.sizes'),
            className: 'text-left',
        },
        {
            title: t('PrintifyProducts.colors'),
            className: 'text-left',
        },
        {
            title: t('PrintifyProducts.min_price_subscription'),
            className: 'text-left',
        },

    ];

    const selectCountry = async (countryCode) => {
        setPage(1);
        setCountryCode(countryCode);
    };

    const handlePageClick = async (event) => {
        setAmazonProducts([])
        setAmazonProductsPage(event.selected + 1);
        await getAmazonProductList(amazonProductID, event.selected + 1);
    };

    const regionList = Object.keys(global.config.printifyRegions).map(function (key) {
        return <a key={key} className={"btn my-1 " + (countryCode == global.config.printifyRegions[key] ? 'btn-primary' : 'btn-outline-dark') + " btn-xs mr-2 d-flex align-items-center mb-2"} onClick={() => { selectCountry(global.config.printifyRegions[key]); setLoader(true) }}><span className={key ? `fi fi-${key.toLocaleLowerCase()} mr-1` : ''}></span> {global.config.printifyRegions[key]}</a>
    });

    const commafy = (num) => {
        var str = num.toString().split('.');
        if (str[0].length >= 5) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

    const getWeightFromGram = (weight) => {
        weight = parseFloat(weight);
        if (weight > 453.53) {
            return parseFloat(weight * 0.00220462, 2).toFixed(0) + ' lbs';
        }
        return parseFloat(weight * 0.035274, 2).toFixed(0) + ' Oz';
    };

    return (
        <>
            <div>
                <Card className='rounded-0'>
                    <Card.Body>
                        <div className='topfilter'>
                            <div className="d-flex align-items-center flex-wrap mb-2">
                                <p className="m-0 mr-3"><strong>{t('product_research.top_sales.filter.country')}</strong></p>
                                {regionList}
                            </div>
                            <CategoryPrintify singleCategory={false} handleCategory={handleCategory} categoryId1={categoryId1} setLoader={setLoader} />
                        </div>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body className='p-2'>
                        <Table responsive className="w-100">
                            <div id="example_wrapper" className="dataTables_wrapper">
                                <InfiniteScrollWrapper
                                    isInfiniteScrollOn={true}
                                    lengthData={items.length}
                                    functionNext={functionNext}
                                    hasMore={hasMore}
                                >
                                    <table id="example" className="display w-100 dataTable">
                                        <thead>
                                            <tr role="row">
                                                {tableHead.map((value, index) => (
                                                    (index !== tableHead.length - 1) ?
                                                        <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? 'sorting-desc-blue' : (value.sort ? 'sorting-desc-icon' : ''))} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                                        :
                                                        (countryCode === 'USA') && <th key={index} className={(value.className || '') + ' ' + (value.sort && value.sort == sorting.sort ? 'sorting-desc-blue' : (value.sort ? 'sorting-desc-icon' : ''))} onClick={(e) => { value.sort && handleSort(value.sort) }} dangerouslySetInnerHTML={{ __html: value.title }}></th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {items.map((product, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>
                                                        <div className='d-flex align-items-center'>
                                                            <div className="prd-img mr-3">
                                                                <OverlayTrigger
                                                                    key="right"
                                                                    trigger={["hover", "focus"]}
                                                                    placement="right"
                                                                    rootClose
                                                                    overlay={
                                                                        <Popover>
                                                                            <Popover.Body>
                                                                                {product.hoverImage &&
                                                                                    <img alt="" width={500} src={`https://images.printify.com/api/catalog/${product.hoverImage}.jpg?s=320`} />
                                                                                }
                                                                            </Popover.Body>
                                                                        </Popover>
                                                                    }
                                                                >
                                                                    <Button variant="link" className='p-0' size="xs">
                                                                        {/* <Link to={'/influencers/details/' + influencer.id + '/breakout'} target="_blank"> */}
                                                                        {product.mainImage &&
                                                                            <img alt="" width={70} className="prod-thum-img" src={`https://images.printify.com/api/catalog/${product.mainImage}.jpg?s=320`} />
                                                                        }
                                                                        {/* </Link> */}
                                                                    </Button>
                                                                </OverlayTrigger>
                                                            </div>
                                                            <div className='prd-title'>
                                                                {/* <Link to={'/influencers/details/' + influencer.id + "/" + 'breakout'} target="_blank"
                                                            > */}
                                                                <p>{product.name || '-'}</p>
                                                                {/* </Link> */}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>{product.brandName}</td>
                                                    <td><i className="fa fa-usd" aria-hidden="true"></i>&nbsp;{product.region.minPrice}</td>
                                                    <td>{product.region.sizesCount}</td>
                                                    <td>{product.region.colorsCount}</td>
                                                    {countryCode === 'USA' && <td>
                                                        <Button variant="outline-primary" className="btn-xs" onClick={() => {
                                                            setAmazonProductID(product._id); handleShowAmazonProductModal(product);
                                                        }}><i className="fa fa-eye mr-1" aria-hidden="true"></i></Button>
                                                    </td>}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </InfiniteScrollWrapper>
                            </div>
                        </Table>
                    </Card.Body>
                </Card>
            </div >

            <Modal
                show={showAmazonProductModal}
                onHide={handleCloseAmazonProductModal}
                backdrop="static" keyboard={false}
                dialogClassName="modal-amazon-products"
                scrollable={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Amazon Products</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <table className="display w-100 dataTable">
                        <thead>
                            <tr role="row">
                                {headList.map((v, i) => (<th key={i} className={(v.className || '')} dangerouslySetInnerHTML={{ __html: v.title }}></th>))}
                            </tr>
                        </thead>
                        <tbody>
                            {amazonProducts.length > 0 && amazonProducts.map((v, i) => (
                                <tr key={i}>
                                    <td>{i + 1}</td>
                                    <td className="text-left">
                                        <div className="d-flex">
                                            <div className="prd-img mr-3">
                                                <OverlayTrigger
                                                    key="right"
                                                    trigger={["hover", "focus"]}
                                                    placement="right"
                                                    rootClose
                                                    overlay={
                                                        <Popover>
                                                            <Popover.Body>
                                                                {v?.image && <img src={v?.image} alt="" width={500} />}
                                                            </Popover.Body>
                                                        </Popover>
                                                    }
                                                >
                                                    <Button variant="link" className='p-0' size="xs">
                                                        {v?.image && <img src={v?.image} alt="" width={500} />}
                                                    </Button>
                                                </OverlayTrigger>
                                            </div>
                                            <div className="prd-title">
                                                <p><span className='font-weight-normal'>{v?.title.length > 40 ? v?.title.substring(0, 40) + '...' : v?.title}</span></p>
                                                <p className='small'><Link to={`https://amazon.com/dp/${v?.asin}`} target='_blank'>{v?.asin || '-'}</Link></p>
                                                {countryCode !== "Other" && <p className='small'>
                                                    {countryCode && <span className={`fi fi-${Object.keys(global.config.printifyRegions).find(key => global.config.printifyRegions[key] === countryCode).toLowerCase()}`}></span>}
                                                    <span className={`fi`}></span>{countryCode || '-'}</p>}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-left">{v?.brand ? v.brand : '-'}</td>
                                    <td className="text-left">{v?.category ? v.category : '-'}</td>
                                    <td className="text-left">{v?.price ? '$' + v.price : '-'}</td>
                                    <td className="text-left">{v?.best_seller_rank ? commafy(v.best_seller_rank) : '-'}</td>
                                    <td className="text-left">{v?.rating ? v.rating : '-'}</td>
                                    <td className="text-left">{v?.review_count ? commafy(v.review_count) : '-'}</td>
                                    <td className="text-left">{v?.weight ? getWeightFromGram(v.weight) : '-'}</td>
                                    <td className="text-left">{v?.monthly_sales ? v.monthly_sales : '-'}</td>
                                    <td className="text-left">{v?.listedSince ? moment(new Date(v.listedSince)).format('YYYY-MM-DD') : '-'}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    {amazonProductsLoader &&
                        <div className='d-flex justify-content-center align-items-center my-5'>
                            <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner>
                        </div>
                    }
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <div className="dataTables_info">
                            {recordsCount == 0 && !amazonProductsLoader ? "No - Record Found" :
                                `Showing ${(((amazonProductsPage - 1) * limit) + 1)} to ${Math.min(amazonProductsPage * limit, recordsCount)} of ${recordsCount} entries`}
                        </div>
                        <div className="dataTables_paginate paging_simple_numbers">
                            <ReactPaginate
                                nextLabel="Next >"
                                onPageChange={(e) => { handlePageClick(e); }}
                                pageRangeDisplayed={2}
                                marginPagesDisplayed={1}
                                pageCount={pageCount}
                                forcePage={amazonProductsPage - 1}
                                previousLabel="< Previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakLabel="..."
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default PrintifyProducts
