import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, OverlayTrigger, Popover, Row, Table, Tooltip } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Select from "react-select";
import { Context } from '../../../MasterLayout.js';
import Axios from '../../plugins/axios';
import formattedNumber from '../CommonComponents/FormatNumberUS.js';
import NetProfitChart from './NetProfitChart';
import OrdersTable from './OrdersTable.js';
import ProfitLanding from "./ProfitLanding";
import StoreFetch from "./StoreFetch";
import StorePerformance from "./StorePerformance";
import UnitSoldChart from './UnitSoldChart.js';


const SummaryCard = ({ title, icon, iconsx, value, link, yesterdayValue, change, startDate, linkYesterday }) => {
  const { t } = useTranslation();

  return (
    <Col lg="4">
      <Card>
        <Card.Body className="px-0 py-2">
          <div className="row justify-content-center px-3">
            <Col>
              <h5>
                <i className={`fa ${iconsx}`} aria-hidden="true"></i> {title}
              </h5>
            </Col>
            <Col className="text-end">
              <p className="mb-0">
                {startDate ? moment(new Date(startDate)).format('MMM DD, YYYY') : '--/--/--'}
              </p>
            </Col>
          </div>
          <hr className="mt-0" />
          <div className="px-3">
            <Link to={link} target="_blank">
              <h2 className="text-primary">{value}</h2>
            </Link>
            <div className="row">
              <div className="col-4">
                <h4>{t('dashboard.yesterday')}</h4>
                <Link to={linkYesterday} target="_blank">
                  <h4 className="text-primary font-w400">{yesterdayValue}</h4>
                </Link>
              </div>
              <div className="col-4 pe-0">
                <h4>{t('dashboard.change')}</h4>
                <h4 className="text-primary font-w400">{change}%</h4>
              </div>
              <div className="col-4">
                <h5 className="fs-80 text-center mb-0">
                  <i className={`fa ${icon}`} aria-hidden="true"></i>
                </h5>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};



const ProfitDashboard = () => {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [stores, setStores] = useState([]);
  const [productCount, setProductCount] = useState(0);
  const [currentStore, setCurrentStore] = useState('');
  const [summaryData, setSummaryData] = useState(null);
  const [items, setItems] = useState([]);
  const [showBrackdown, setShowBrackdown] = useState(false);
  const [orderPagination, setOrderPagination] = useState({
    limit: 10,
    page: 1,
    recordsCount: 0,
    pageCount: 0
  });
  const [storePerformanceDateRange, setStorePerformanceDateRange] = useState({
    'yesterday': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
      end_date: new Date()
    },
    'last_7_days': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
      end_date: new Date()
    },
    'last_30_days': {
      start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
      end_date: new Date()
    }
  });
  let todaysDate = moment(new Date()).format('YYYY-MM-DD');
  let yesterdaysDate = moment(new Date(new Date().setDate(new Date().getDate() - 1))).format('YYYY-MM-DD');
  const [bestSellerDateRange, setBestSellerDateRange] = useState({ start_date: new Date(new Date().setDate(new Date().getDate() - 30)), end_date: new Date() });
  const [dateStrings, setDateStrings] = useState({
    todaysDateString: `start_date=${todaysDate}&end_date=${todaysDate}`,
    yesterdaysDateString: `start_date=${yesterdaysDate}&end_date=${yesterdaysDate}`
  });

  useEffect(() => {
    getStores();
  }, []);

  useEffect(() => {
    if (currentStore) getSummaryReport();
  }, [currentStore]);

  useEffect(() => {
    if (currentStore) getOrders();
  }, [orderPagination.page, orderPagination.limit, currentStore]);

  const getStores = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get('/api/dashboard/stores').catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        setLoadedOnce(true);
        response.data.data.stores.map((v) => {
          v.value = v._id;
          v.label = v.name;
          return v;
        });
        setStores(response.data.data.stores);
        let storeIndex = 0;
        if (response.data?.data?.default_store) {
          storeIndex = response.data.data.stores.findIndex(n => n._id == response.data.data.default_store)
        };
        setCurrentStore(response.data.data.stores[storeIndex]);
        setProductCount(response.data?.data?.productCount);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const getSummaryReport = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.post('/api/dashboard/summary', { store_id: currentStore._id, store_trend_key: "current_month" }).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        (response.data?.data?.order_detail && response.data?.data?.best_seller) ? setSummaryData(response.data?.data) : setSummaryData([]);
        if (response.data?.data?.order_detail && response.data?.data?.best_seller) setBestSellerDateRange({ start_date: new Date(response.data.data.best_seller.value.last_30_days.start_date), end_date: new Date(response.data.data.best_seller.value.last_30_days.end_date) });
        setProductCount(response.data?.data?.productCount ? response.data.data.productCount : 0);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const getOrders = async () => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.get(`/api/orders?store_id=${currentStore._id}&page=${orderPagination.page}&limit=${orderPagination.limit}`).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200 && response.data?.data) {
        setOrderPagination((pre) => ({
          ...pre,
          page: response.data.data.currentPage,
          pageCount: response.data.data.totalPages,
          recordsCount: response.data.data.recordsCount,
        }))
        setItems(response.data.data.results)
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const handleApply = (event, picker) => {
    picker.element.val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'));
    setBestSellerDateRange({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
    getBestSeller({ start_date: picker.startDate.format('YYYY-MM-DD'), end_date: picker.endDate.format('YYYY-MM-DD') })
  };


  const handleDateRange = (name, filter) => {
    let dateRangeFilter = { ...storePerformanceDateRange };
    dateRangeFilter[name] = filter;
    setStorePerformanceDateRange(dateRangeFilter);
  };

  const getBestSeller = async (obj) => {
    try {
      dispatch({ type: 'loader_show', response: true });
      let response = await Axios.post('/api/dashboard/best-sellers', {
        store_id: currentStore._id, ...obj
      }).catch((e) => console.error('Axios-Catch: ' + e.message));
      dispatch({ type: 'subscription_expired', response: response.data.statusCode });

      if (response && response.status === 200) {
        let summaryObj = { ...summaryData };
        summaryObj.best_seller.value.last_30_days = response.data.data;
        setSummaryData(summaryObj);
      };
    } catch (error) {
      console.error('Error-Catch: ' + error.message);
    };
    dispatch({ type: 'loader_hide', response: true });
  };

  const handlePageClick = (event) => {
    setOrderPagination((pre) => ({
      ...pre,
      page: event.selected + 1
    }));
  };

  const periods = [
    { key: 'yesterday', label: t('dashboard.yesterday') },
    { key: 'last_7_days', label: '7 day' },
    { key: 'last_30_days', label: '30 day' },
    { key: 'last_60_days', label: '60 day' },
    { key: 'last_90_days', label: '90 day' },
    { key: 'month_to_date', label: t('dashboard.month_to_date') },
    { key: 'year_to_date', label: t('dashboard.year_to_date') },
  ];

  const renderPeriodData = (key, property, formatter = (val) => val) =>
    summaryData?.order_detail?.value?.[key]?.current?.[property]
      ? formatter(parseFloat(summaryData.order_detail.value[key].current[property]).toFixed(2))
      : '0';

  const formattedValue = (value) =>
    value ? `$ ${formattedNumber(parseFloat(value).toFixed(2))}` : '0';
  const formattedCount = (count) =>
    count ? formattedNumber(parseFloat(count).toFixed(2)) : '0';
  const calculateChange = (today, yesterday) =>
    yesterday
      ? formattedNumber(((today - yesterday) / (yesterday || 1) * 100).toFixed(2))
      : yesterday === 0 ? -100 : '--';

  const today = summaryData?.order_detail?.value?.today?.current || {};
  const yesterday = summaryData?.order_detail?.value?.yesterday?.current || {};

  return (
    <>
      {stores.length != 0 &&
        <>
          <div className="revenue-section">
            <Row className="align-items-center mb-3">
              <Col md="9" className="text-center text-sm-left">
              </Col>
              <Col md="3" className="">
                <div className="dropdown product-rating-menu mr-2">
                  <Select
                    closeMenuOnSelect={true}
                    defaultValue={currentStore}
                    options={stores}
                    onChange={(e) => setCurrentStore(e)}
                  />
                </div>
              </Col>
            </Row>
          </div>
          {productCount != 0 &&
            <>
              <div className="revenue-section">
                <Row className="align-items-center mb-3">
                  <Col md="6" className="text-center text-sm-left">
                    <h4 className="fw-bolder">{t('dashboard.revenue_summary')}</h4>
                  </Col>
                </Row>
                <Row>
                  {/* Gross Revenue */}
                  <SummaryCard
                    title={t('dashboard.today_gross_revenue')}
                    iconsx="fa-info-circle"
                    icon="fa fa-handshake-o"
                    value={formattedValue(today.total_payment)}
                    link={`/tiktok-store/orders?${dateStrings.todaysDateString}`}
                    yesterdayValue={formattedValue(yesterday.total_payment)}
                    linkYesterday={`/tiktok-store/orders?${dateStrings.yesterdaysDateString}`}
                    change={calculateChange(today.total_payment, yesterday.total_payment)}
                    startDate={summaryData?.order_detail?.value?.today?.start_date}
                  />

                  {/* Orders */}
                  <SummaryCard
                    title={t('dashboard.today_orders')}
                    iconsx="fa-info-circle"
                    icon="fa-shopping-bag"
                    value={formattedCount(today.order_count)}
                    link={`/tiktok-store/orders?${dateStrings.todaysDateString}`}
                    yesterdayValue={formattedCount(yesterday.order_count)}
                    linkYesterday={`/tiktok-store/orders?${dateStrings.yesterdaysDateString}`}
                    change={calculateChange(today.order_count, yesterday.order_count)}
                    startDate={summaryData?.order_detail?.value?.today?.start_date}
                  />

                  {/* Net Profits */}
                  <SummaryCard
                    title={t('dashboard.today_Profits')}
                    iconsx="fa-info-circle"
                    icon="fa-line-chart"
                    value={formattedValue(today?.net_profit)}
                    link={`/tiktok-store/orders?${dateStrings.todaysDateString}`}
                    yesterdayValue={formattedValue(yesterday?.net_profit)}
                    linkYesterday={`/tiktok-store/orders?${dateStrings.yesterdaysDateString}`}
                    change={calculateChange(today?.net_profit, yesterday?.net_profit)}
                    startDate={summaryData?.order_detail?.value?.today?.start_date}
                  />
                </Row>
              </div>

              <div className="store-performance-section">
                <Row>
                  <Col>
                    <h4 className="fw-bolder">{t('dashboard.store_performance')}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    {summaryData?.order_detail?.value?.today && currentStore && <StorePerformance date={storePerformanceDateRange['yesterday']} name={"yesterday"} func={handleDateRange} currentStore={currentStore} />}
                  </Col>
                  <Col lg="4">
                    {summaryData?.order_detail?.value?.last_7_days && currentStore && <StorePerformance date={storePerformanceDateRange['last_7_days']} name={"last_7_days"} func={handleDateRange} currentStore={currentStore} />}
                  </Col>
                  <Col lg="4">
                    {summaryData?.order_detail?.value?.last_30_days && currentStore && <StorePerformance date={storePerformanceDateRange['last_30_days']} name={"last_30_days"} func={handleDateRange} currentStore={currentStore} />}
                  </Col>
                </Row>
              </div>

              <div className="store-trends-section">
                <Row>
                  <Col>
                    <h4 className="fw-bolder">{t('dashboard.store_trends')}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <Card>
                      <Card.Body className="p-0">
                        <div className="row justify-content-center p-3">
                          {/* Remove bellow call */}
                          <Col className="text-center p-5">
                            <div style={{ "height": "400px" }}>
                              {summaryData?.store_trend && < UnitSoldChart data={summaryData?.store_trend} />}
                            </div>
                          </Col>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                  <Col lg="6">
                    <Card>
                      <Card.Body className="p-0">
                        <div className="row justify-content-center p-3">
                          {/* Remove bellow call */}
                          <Col className="text-center p-5">
                            <div style={{ "height": "400px" }}>
                              {summaryData?.store_trend && < NetProfitChart data={summaryData?.store_trend} />}
                            </div>
                          </Col>
                        </div>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="bestsellers-section">
                <Row className="align-items-center mb-3">
                  <Col md="6" className="text-center text-sm-left">
                    <h4 className="fw-bolder">Today's Orders</h4>
                  </Col>
                  <Col md="6" className="text-center text-sm-right">
                    <Button variant="link" className='p-0' size="xs">
                      <Link className="btn btn-primary btn-xs px-3 text-white" to={`/tiktok-store/orders?${dateStrings.todaysDateString}`} target="_blank">
                        See More
                      </Link>
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body className="p-0">
                        <OrdersTable items={items} orderPagination={orderPagination} handlePageClick={handlePageClick} />
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>


              <div className="bestsellers-section">
                <Row className="align-items-center mb-3">
                  <Col md="6" className="text-center text-sm-left">
                    <h4 className="fw-bolder">{t('dashboard.bestsellers')}</h4>
                  </Col>
                  <Col md="6" className="">
                    <div className="d-flex align-items-center justify-content-end flex-column flex-sm-row">
                      <DateRangePicker onApply={handleApply} initialSettings={{
                        startDate: moment(new Date(bestSellerDateRange.start_date)).format('MM/DD/YYYY'), endDate: moment(new Date(bestSellerDateRange.end_date)).format('MM/DD/YYYY'),
                        ranges: {
                          'Today': [moment().toDate(), moment().toDate()],
                          'Yesterday': [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
                          'Last 7 Days': [moment().subtract(6, 'days').toDate(), moment().toDate()],
                          'Last 30 Days': [moment().subtract(29, 'days').toDate(), moment().toDate()],
                          'This Month': [moment().startOf('month').toDate(), moment().toDate()],
                          'Last Month': [moment().subtract(1, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                          'Last 3 Month': [moment().subtract(3, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                          'Last 6 Month': [moment().subtract(6, 'month').startOf('month').toDate(), moment().subtract(1, 'month').endOf('month').toDate()],
                        },
                      }}>
                        <input type="text" className="form-control w-auto form-control-sm input-daterange-timepicker pull-right" />
                      </DateRangePicker>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body className="p-0">
                        <Table responsive className="w-100">
                          <thead>
                            <tr role="row">
                              <th>{t('common.rank')}</th>
                              <th>{t('dashboard.product_details')}</th>
                              <th dangerouslySetInnerHTML={{ __html: t('dashboard.total_revenue') }}></th>
                              <th dangerouslySetInnerHTML={{ __html: t('dashboard.avg_selling_price') }}></th>
                              <th dangerouslySetInnerHTML={{ __html: t('dashboard.tiktok_expenses_th') }}></th>
                              <th>{t('dashboard.cogs')}</th>
                              <th dangerouslySetInnerHTML={{ __html: t('dashboard.net_profit_th') }}></th>
                              <th>{t('dashboard.margin')}</th>
                              <th dangerouslySetInnerHTML={{ __html: t('dashboard.units_sold_th') }}></th>
                            </tr>
                          </thead>
                          <tbody>
                            {summaryData?.best_seller?.value?.last_30_days?.current?.map((v, i) => (
                              <tr key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  <div className="d-flex">
                                    <div className="prd-img mr-3">
                                      <OverlayTrigger
                                        key="right"
                                        trigger={["hover", "focus"]}
                                        placement="right"
                                        rootClose
                                        overlay={
                                          <Popover>
                                            <Popover.Body>
                                              {v?.product?.main_images && v?.product?.main_images?.length > 0 && v?.product?.main_images[0]?.thumb_urls && v?.product?.main_images[0]?.thumb_urls.length > 0 && <img src={v?.product.main_images[0].thumb_urls[0]} alt="" width={500} className="prod-thum-img" />}
                                            </Popover.Body>
                                          </Popover>
                                        }
                                      >
                                        <Button variant="link" className='p-0' size="xs">
                                          <Link to={'/products/' + v?.product?.product_id + '/details'} target="_blank">
                                            {v?.product?.main_images && v?.product?.main_images?.length > 0 && v?.product?.main_images?.[0]?.thumb_urls && v?.product?.main_images?.[0]?.thumb_urls?.length > 0 && <img src={v?.product?.main_images?.[0]?.thumb_urls?.[0]} alt="" width={70} className="prod-thum-img" />}
                                          </Link>
                                        </Button>
                                      </OverlayTrigger>
                                    </div>
                                    <div className="prd-title">
                                      <p>
                                        <OverlayTrigger overlay={<Tooltip>{v?.product?.title}</Tooltip>}>
                                          <Link to={'/products/' + v?.product?.product_id + '/details'} target="_blank">
                                            <span>{v?.product?.title ? v?.product?.title?.length > 50 ? v?.product?.title.substring(0, 50) + '...' : v?.product?.title : '-'}</span>
                                          </Link>
                                        </OverlayTrigger>
                                      </p>
                                    </div>
                                  </div>
                                </td>
                                <td>$ {formattedNumber(parseFloat(v?.total_payment).toFixed(2))}</td>
                                <td>$ {parseFloat(v?.unit_sold) > 0 ? formattedNumber(parseFloat(parseFloat(v?.total_payment) / parseFloat(v?.unit_sold)).toFixed(2)) : '-'}</td>
                                <td>$ {formattedNumber(parseFloat(v?.total_charges).toFixed(2))}</td>
                                <td>$ {formattedNumber(parseFloat(v?.total_cost).toFixed(2))}</td>
                                <td>$ {formattedNumber(parseFloat(v?.net_profit).toFixed(2))}</td>
                                <td>{v?.total_payment && v?.total_payment != 0 ? formattedNumber(parseFloat(((parseFloat(v?.profit_loss) / parseFloat(v?.total_payment)) * 100)).toFixed(2)) + ' %' : '-'}</td>
                                <td>{formattedNumber(parseFloat(v?.unit_sold).toFixed(2))}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>

              <div className="store-summary-section">
                <Row>
                  <Col>
                    <h4 className="fw-bolder">{t('dashboard.store_summary')}</h4>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Card>
                      <Card.Body className="p-0">
                        <Table responsive className="w-100">
                          <thead>
                            <tr>
                              <th className="text-center">
                                <b>{t('dashboard.period')}</b>
                                <br />
                                <p className="fs-12">
                                  {" "}
                                </p>
                              </th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  <b>{period.label}</b>
                                  <br />
                                  <p className="fs-12">
                                    {summaryData?.order_detail?.value?.[period.key]?.start_date
                                      ? moment(new Date(summaryData.order_detail.value[period.key].start_date)).format('MM/DD/YYYY')
                                      : '--/--/--'}{' '}
                                    TO{' '}
                                    {summaryData?.order_detail?.value?.[period.key]?.end_date
                                      ? moment(new Date(summaryData.order_detail.value[period.key].end_date)).format('MM/DD/YYYY')
                                      : '--/--/--'}
                                  </p>
                                </td>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>{t('dashboard.gross_revenue')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  $ {renderPeriodData(period.key, 'total_payment', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <th onClick={() => setShowBrackdown(!showBrackdown)}>
                                <div className="d-flex align-items-center">
                                  < div>
                                    {t('dashboard.tiktok_expenses')}
                                  </div>
                                  < div>
                                    &nbsp;&nbsp;&nbsp;{!showBrackdown ? '+' : '-'}
                                  </div>
                                </div>
                              </th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  $ {renderPeriodData(period.key, 'total_charges', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            {showBrackdown && (
                              <>
                                {['total_commision', 'total_referral_fee_amount', 'total_shipping_price'].map((field, index) => (
                                  <tr key={index}>
                                    <th className="pl-5">{t(`dashboard.${field}`)}</th>
                                    {periods.map((period) => (
                                      <td className="text-center" key={period.key}>
                                        $ {renderPeriodData(period.key, field, formattedNumber)}
                                      </td>
                                    ))}
                                  </tr>
                                ))}
                              </>
                            )}
                            <tr>
                              <th>{t('dashboard.cogs')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  $ {renderPeriodData(period.key, 'total_cost', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <th>{t('dashboard.other_expenses')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  $ {renderPeriodData(period.key, 'other_expenses', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <th>{t('dashboard.net_profit')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  $ {renderPeriodData(period.key, 'net_profit', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <th>{t('dashboard.orders')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  {renderPeriodData(period.key, 'order_count', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <th>{t('dashboard.units_sold')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  {renderPeriodData(period.key, 'total_quantity_sold', formattedNumber)}
                                </td>
                              ))}
                            </tr>
                            <tr>
                              <th>{t('dashboard.margin')}</th>
                              {periods.map((period) => (
                                <td className="text-center" key={period.key}>
                                  {summaryData?.order_detail?.value?.[period.key]?.current?.total_payment &&
                                    summaryData?.order_detail?.value?.[period.key]?.current?.total_payment != 0
                                    ? formattedNumber(
                                      (
                                        (parseFloat(summaryData.order_detail.value[period.key].current.profit_loss) /
                                          parseFloat(summaryData.order_detail.value[period.key].current.total_payment)) *
                                        100
                                      ).toFixed(2)
                                    ) + ' %'
                                    : '-'}
                                </td>
                              ))}
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </div>
            </>
          }
          {productCount == 0 && loadedOnce && <StoreFetch />}
        </>
      }
      {stores.length == 0 && loadedOnce && <ProfitLanding />}
    </>
  );
};

export default ProfitDashboard;