import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import {
    Card,
    Col,
    Collapse,
    OverlayTrigger,
    Popover,
    Spinner,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Context } from "../../../MasterLayout";
import Axios from "../../plugins/axios";
import formattedNumber from "../CommonComponents/FormatNumberUS";

const todaysDate = moment(new Date()).format("YYYY-MM-DD");

function StorePerformance({ currentStore, propsData, skuArr }) {
  const { t } = useTranslation();
  const { dispatch } = useContext(Context);
  const [open, setOpen] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [summaryData, setSummaryData] = useState(null);
  const [dateStrings, setDateStrings] = useState(
    `start_date=${todaysDate}&end_date=${todaysDate}`
  );

  useEffect(() => {
    getSummaryReport();
    setDateStrings(
      `start_date=${moment(propsData.start_date).format(
        "YYYY-MM-DD"
      )}&end_date=${moment(propsData.end_date).format("YYYY-MM-DD")}`
    );
  }, [currentStore, propsData, skuArr]);

  const getSummaryReport = async () => {
    try {
      setSpinner(true);
      let response = await Axios.post("/api/dashboard/store-performance", {
        store_id: currentStore._id,
        start_date: propsData.start_date,
        end_date: propsData.end_date,
        sku_id: skuArr.map((v) => v?.sku_id),
      }).catch((e) => console.error("Axios-Catch: " + e.message));
      dispatch({
        type: "subscription_expired",
        response: response.data.statusCode,
      });

      if (response && response.status === 200) {
        setSummaryData(response.data.data);
      }
    } catch (error) {
      console.error("Error-Catch: " + error.message);
    }
    setSpinner(false);
  };

  return (
    <Card className="border-0 rounded-0 w-100">
      {spinner && (
        <div className="loader-warp">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      <Card.Body className="p-0">
        <div
          className={`d-flex flex-column align-items-center card-head p-3 text-white card-head ${
            propsData?.className || ""
          }`}
        >
          <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
            <p className={`text-white m-0 p-0 lh-base`}>
              <strong>{propsData.name}</strong>
            </p>
          </Link>
          <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
            <p className="text-white m-0 p-0 lh-base">
              {moment(propsData.start_date).format("MM/DD/YYYY")} -{" "}
              {moment(propsData.end_date).format("MM/DD/YYYY")}
            </p>
          </Link>
        </div>
        <div className="card-bd p-3">
          <div className="mb-2">
            <p className="me-2 mb-0 dashboard-h-text">Gross Revenue</p>
            <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
              <span className="lh-base fs-4 dashboard-number">
                ${" "}
                {formattedNumber(
                  parseFloat(summaryData?.current?.total_payment || 0).toFixed(
                    2
                  )
                )}
              </span>
            </Link>
          </div>
          <hr className="dashboard-hr" />
          <div className="d-flex justify-content-between mb-2">
            <p className="me-2 mb-0 dashboard-h-text">
              Orders/units
              <br />
              <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
                <span className="dashboard-h-text text-black lh-base fs-4">
                  {formattedNumber(
                    parseFloat(summaryData?.current?.order_count || 0).toFixed(
                      2
                    )
                  )}
                  /
                  {formattedNumber(
                    parseFloat(
                      summaryData?.current?.total_quantity_sold || 0
                    ).toFixed(2)
                  )}
                </span>
              </Link>
            </p>
            {/* <p className="me-2 mb-0 dashboard-h-text"><small><b>Margin</b></small><br />
                            <Link to={"#"} className='dashboard-h-text text-black'>{summaryData?.current?.total_payment && summaryData?.current?.total_payment != 0 ? formattedNumber(parseFloat(((parseFloat(summaryData?.current?.profit_loss) / parseFloat(summaryData.current.total_payment)) * 100)).toFixed(2)) + ' %' : '-'}</Link></p> */}
            <p className="me-2 mb-0 dashboard-h-text">
              Units Sold
              <br />
              <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
                <span className="dashboard-h-text text-black lh-base fs-4">
                  {formattedNumber(
                    parseFloat(
                      summaryData?.current?.total_quantity_sold || 0
                    ).toFixed(2)
                  )}
                </span>
              </Link>
            </p>
          </div>
          <hr className="dashboard-hr" />
          {/* <div className="mb-2">
                        <p className="m-0 p-0 lh-base dashboard-h-text"><b>COGS</b></p>
                        <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank" className='dashboard-h-text text-black'>
                            <p className="m-0 p-0 lh-base fs-3">$ {formattedNumber(parseFloat(summaryData?.current?.total_cost || 0).toFixed(2))}</p>
                        </Link>
                    </div> */}
          <div className="d-flex justify-content-between mb-2">
            <p className="me-2 mb-0 dashboard-h-text">
              COGS
              <br />
              <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
                <span className="dashboard-h-text text-black lh-base fs-4">
                  ${" "}
                  {formattedNumber(
                    parseFloat(summaryData?.current?.total_cost || 0).toFixed(2)
                  )}
                </span>
              </Link>
            </p>
            <p className="me-2 mb-0 dashboard-h-text">
              Margin
              <br />
              <span className="dashboard-h-text text-black lh-base fs-4">
                {summaryData?.current?.total_payment &&
                summaryData?.current?.total_payment != 0
                  ? formattedNumber(
                      parseFloat(
                        (parseFloat(summaryData?.current?.profit_loss) /
                          parseFloat(summaryData.current.total_payment)) *
                          100
                      ).toFixed(2)
                    ) + " %"
                  : "-"}
              </span>
            </p>
          </div>
          <hr className="dashboard-hr" />
          <div className="mb-0">
            <p className="me-2 mb-0 dashboard-h-text">Net Profit</p>
            <Link to={`/tiktok-store/orders?${dateStrings}`} target="_blank">
              <span className="text-primary dashboard-number lh-base fs-4">
                ${" "}
                {formattedNumber(
                  parseFloat(summaryData?.current?.net_profit || 0).toFixed(2)
                )}
              </span>
            </Link>
          </div>
          <div className="text-end">
            <OverlayTrigger
              key="right"
              trigger={["click"]}
              placement="auto"
              rootClose
              overlay={
                <Popover>
                  <Popover.Body>
                    <div className="row">
                      <Col lg="6">
                        <p className="mb-0">{t("dashboard.gross_revenue")}:</p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          $
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.total_payment || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>
                      <Col lg="7">
                        <p className="mb-0">
                          {t("dashboard.tiktok_expenses")}:{" "}
                          <i
                            className="fa fa-arrow-right"
                            onClick={() => setOpen(!open)}
                            aria-controls="example-collapse-text"
                            aria-expanded={open}
                            aria-hidden="true"
                          ></i>
                        </p>
                      </Col>
                      <Col lg="5" className="text-right">
                        <p className="mb-0">
                          - $
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.total_charges || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>
                      <Collapse in={open}>
                        <div id="example-collapse-text">
                          <div className="row ml-1">
                            <Col lg="6">
                              <p className="mb-0">
                                {t("dashboard.commission")}:
                              </p>
                            </Col>
                            <Col lg="6" className="text-right">
                              <p className="mb-0">
                                - $
                                {formattedNumber(
                                  parseFloat(
                                    summaryData?.current?.total_commision || 0
                                  ).toFixed(2)
                                )}
                              </p>
                            </Col>
                            <Col lg="12" className="text-right">
                              <hr className="m-0" />
                            </Col>
                            <Col lg="6">
                              <p className="mb-0">
                                {t("dashboard.referral_fee")}:
                              </p>
                            </Col>
                            <Col lg="6" className="text-right">
                              <p className="mb-0">
                                - $
                                {formattedNumber(
                                  parseFloat(
                                    summaryData?.current
                                      ?.total_referral_fee_amount || 0
                                  ).toFixed(2)
                                )}
                              </p>
                            </Col>
                            <Col lg="12" className="text-right">
                              <hr className="m-0" />
                            </Col>
                            {parseFloat(
                              summaryData?.current?.total_shipping_price
                            ) > 0 && (
                              <>
                                <Col lg="6">
                                  <p className="mb-0">
                                    {t("dashboard.shipping_fee")}:
                                  </p>
                                </Col>
                                <Col lg="6" className="text-right">
                                  <p className="mb-0">
                                    - $
                                    {formattedNumber(
                                      parseFloat(
                                        summaryData.current
                                          .total_shipping_price || 0
                                      ).toFixed(2)
                                    )}
                                  </p>
                                </Col>
                                <Col lg="12" className="text-right">
                                  <hr className="m-0" />
                                </Col>
                              </>
                            )}
                          </div>
                        </div>
                      </Collapse>
                      <Col lg="6">
                        <p className="mb-0">COGS:</p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          - $
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.total_cost || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>
                      <Col lg="6">
                        <p className="mb-0">Other Expenses:</p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          - $
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.other_expenses || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                      {parseFloat(
                        summaryData?.current?.total_our_shipping_charges
                      ) > 0 && (
                        <>
                          <Col lg="12" className="text-right">
                            <hr className="m-0" />
                          </Col>
                          <Col lg="6">
                            <p className="mb-0">
                              {t("dashboard.seller_shipping")}:
                            </p>
                          </Col>
                          <Col lg="6" className="text-right">
                            <p className="mb-0">
                              - $
                              {formattedNumber(
                                parseFloat(
                                  summaryData.current
                                    .total_our_shipping_charges || 0
                                ).toFixed(2)
                              )}
                            </p>
                          </Col>
                        </>
                      )}
                      <Col lg="12" className="text-right">
                        <hr className="m-0" />
                      </Col>
                      <Col lg="6">
                        <p className="mb-0">{t("dashboard.net_profit")}:</p>
                      </Col>
                      <Col lg="6" className="text-right">
                        <p className="mb-0">
                          {" "}
                          $
                          {formattedNumber(
                            parseFloat(
                              summaryData?.current?.net_profit || 0
                            ).toFixed(2)
                          )}
                        </p>
                      </Col>
                    </div>
                  </Popover.Body>
                </Popover>
              }
            >
              <p className="text-primary text-decoration-underline cursor-pointer">
                Details
              </p>
            </OverlayTrigger>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
}

export default StorePerformance;
